import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { mockDataExperts, mockDataHistory } from "../../data/mockData";
import apiClient from "../apiClient";

const ExpertCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [expert, setExpert] = useState(null);
  const [history, setHistory] = useState([]);
  const [streamName, setStreamName] = useState("");

  useEffect(() => {
    const fetchExpertData = async () => {
      try {
        const expertData = await apiClient(`/api/experts/${id}`);
        setExpert(expertData);

        // Дополнительный запрос для получения stream_name
        if (expertData.stream_id) {
          const streamData = await apiClient(`/api/potoks/${expertData.stream_id}`);
          setStreamName(streamData.stream_name);
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных эксперта:", error);
        const fallbackExpert = mockDataExperts.find(exp => exp.id === parseInt(id));
        setExpert(fallbackExpert);
      }
    
      try {
        const historyData = await apiClient(`/api/experts/${id}/history`);
        setHistory(historyData);
      } catch (error) {
        console.error("Ошибка при загрузке истории изменений эксперта:", error);
        const fallbackHistory = mockDataHistory.find(hist => hist.expertId === parseInt(id));
        setHistory(fallbackHistory ? fallbackHistory.history : []);
      }
    };

    fetchExpertData();
  }, [id]);

  const handleBackClick = () => {
    const state = location.state || {};
    navigate(-1, { state });
  };

  useEffect(() => {
    if (location.state?.scrollPosition) {
      window.scrollTo(0, location.state.scrollPosition);
    }
  }, [location.state]);

  if (!expert) {
    return <Typography>Загрузка...</Typography>;
  }

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return (
    <Box p="20px">
      <IconButton onClick={handleBackClick} sx={{ mb: "20px" }}>
        <ArrowBackIcon />
      </IconButton>

      <Box display="flex" mt={2}>
        <Box width="50%" pr={2} borderRight="1px solid #ccc">
          <Typography variant="h4">{expert.full_name}</Typography>
          <Typography variant="body1">Телефон: {expert.phone_number}</Typography>
          <Typography variant="body1">Телеграмм: {expert.telegram_account}</Typography>
          <Typography variant="body1">Получено клиентов: {expert.clients_received}</Typography>
          <Typography variant="body1">Продажи: {expert.earnings}</Typography>
          <Typography variant="body1">Конверсия: {expert.conversion_rate}%</Typography>
          <Typography variant="body1">Средний балл NPS: {expert.average_nps_score}</Typography>
          <Typography variant="body1">Дата регистрации: {formatDateTime(expert.registration_date)}</Typography>
          <Typography variant="body1">Поток: {streamName}</Typography>
          <Typography variant="body1">Язык работы: {expert.language}</Typography>
          <Typography variant="body1">
            Статус: {expert.status === "active" ? "Активен" : "Деактивирован"}
          </Typography>
          <Typography variant="body1">Создано: {formatDateTime(expert.created_at)}</Typography>
        </Box>

        <Box width="50%" pl={2}>
          <Box mt={4}>
            <Typography variant="h5">История изменений:</Typography>
            {history.length === 0 ? (
              <Typography>История изменений отсутствует.</Typography>
            ) : (
              history.map((entry, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body2" color="textSecondary">{formatDateTime(entry.timestamp)}</Typography>
                  <Typography variant="body2">{entry.description}</Typography>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpertCard;
