import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Base from "./scenes/base";
import Contacts from "./scenes/contacts";
import Experts from "./scenes/experts";
import NPSAnalytics from "./scenes/nps";
import NPSPotokDetails from "./scenes/nps/npsdetails.jsx";
import ExpertsAnalytics from "./scenes/repexperts";
import Support from "./scenes/support";
import ClientsAnalytics from "./scenes/repcilients";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Templates from "./scenes/templates/templates";
import Rassylka from "./scenes/rassylka";
import VariablesEdit from "./scenes/templates/VariablesEdit.jsx";
import ClientCard from "./scenes/clientCard/ClientCard";
import ExpertCard from "./scenes/expertCard";
import Potok from "./scenes/potok";
import AddPotok from "./scenes/potok/AddPotok";
import PotokDetails from "./scenes/potok/PotokDetails";
import Login from "./scenes/login"; // Импорт вашего компонента Login

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated") === "true";
    setIsAuthenticated(authStatus);

    if (authStatus && window.location.pathname === "/login") {
      navigate("/contacts"); // Перенаправление на главную страницу, если уже авторизован
    }
  }, [navigate]);

  const logout = () => {
    localStorage.removeItem("isAuthenticated");
    setIsAuthenticated(false);
    navigate("/login");
  };

  const ProtectedRoute = ({ element, ...rest }) => {
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} onLogout={logout} />}
            <Routes>
              <Route path="/" element={<Navigate to="/contacts" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/base" element={<ProtectedRoute element={<Base />} />} />
              <Route path="/contacts" element={<ProtectedRoute element={<Contacts />} />} />
              <Route path="/analytics/nps" element={<ProtectedRoute element={<NPSAnalytics />} />} />
              <Route path="/analytics/nps/:id" element={<ProtectedRoute element={<NPSPotokDetails />} />} />
              <Route path="/analytics/eksperts" element={<ProtectedRoute element={<ExpertsAnalytics />} />} />
              <Route path="/" element={<ProtectedRoute element={<Support />} />} />
              <Route path="/analytics/clients" element={<ProtectedRoute element={<ClientsAnalytics />} />} />
              <Route path="/templates" element={<ProtectedRoute element={<Templates />} />} />
              <Route path="/templates/edit/:id" element={<ProtectedRoute element={<VariablesEdit />} />} />
              <Route path="/rassylka" element={<ProtectedRoute element={<Rassylka />} />} />
              <Route path="/client/:id" element={<ProtectedRoute element={<ClientCard />} />} />
              <Route path="/experts" element={<ProtectedRoute element={<Experts />} />} />
              <Route path="/experts/:id" element={<ProtectedRoute element={<ExpertCard />} />} />
              <Route path="/potok" element={<ProtectedRoute element={<Potok />} />} />
              <Route path="/potok/add" element={<ProtectedRoute element={<AddPotok />} />} />
              <Route path="/potok/:id" element={<ProtectedRoute element={<PotokDetails />} />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
