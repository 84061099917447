import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, IconButton, Button, TextField, Autocomplete, Card, CardContent, Chip, Avatar, Divider, Tooltip } from "@mui/material";
import { lazy, Suspense } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { 
  CheckCircleOutline, CancelOutlined, InfoOutlined, EditOutlined, PersonAddOutlined, 
  PersonRemoveOutlined, AccountCircleOutlined, BlockOutlined, RestoreOutlined, 
  AssignmentTurnedInOutlined, ThumbUpOutlined, ThumbDownOutlined, BugReportOutlined, 
  MessageOutlined, SupervisorAccountOutlined, StarOutline
} from '@mui/icons-material';
import apiClient from '../apiClient';
import { mockDataContacts, mockDataExperts, mockDataHistory } from "../../data/mockData";

// Функция для выбора иконки в зависимости от типа действия
const getActionIcon = (actionType) => {
  const iconMap = {
    'created': CheckCircleOutline,
    'rejected': CancelOutlined,
    'info': InfoOutlined,
    'edited': EditOutlined,
    'added': PersonAddOutlined,
    'removed': PersonRemoveOutlined,
    'blocked': BlockOutlined,
    'restored': RestoreOutlined,
    'completed': AssignmentTurnedInOutlined,
    'liked': ThumbUpOutlined,
    'disliked': ThumbDownOutlined,
    'issue': BugReportOutlined,
    'message': MessageOutlined,
    'assigned': SupervisorAccountOutlined,
    'rated': StarOutline,
    'accepted': CheckCircleOutline,
    'created_issue': BugReportOutlined,
    'nps_score': StarOutline,
    'changed_status_admin': EditOutlined
  };

  const IconComponent = iconMap[actionType] || AccountCircleOutlined;
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <IconComponent fontSize="small" />
    </Suspense>
  );
};

// Функция для замены ID в тексте на имена с запросом к API
const replaceIdsWithNames = (text, variable, statuses, experts) => {
  if (!variable || typeof text !== 'string') return text;
  
  let newText = text;

  Object.entries(variable).forEach(([key, value]) => {
    if (["old_expert_id", "new_expert_id", "accepted_expert_id", "rejected_expert_id", "expert_id"].includes(key)) {
      const expert = experts.find(e => e.id === value);
      if (expert) {
        newText = newText.replace(new RegExp(`с ID ${value}`, 'g'), `с ${expert.full_name}`);
        newText = newText.replace(new RegExp(`на ID ${value}`, 'g'), `на ${expert.full_name}`);
      }
    }

    if (["old_status", "new_status"].includes(key)) {
      const status = statuses.find((s) => s.id === value);
      if (status) {
        newText = newText.replace(new RegExp(`'${value}'`, 'g'), `'${status.name}'`);
      }
    }
  });

  return newText;
};

const ClientCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [client, setClient] = useState(null);
  const [experts, setExperts] = useState([]);
  const [history, setHistory] = useState([]);
  const [updatedHistory, setUpdatedHistory] = useState([]);
  const [streamName, setStreamName] = useState("Загрузка...");
  const [statuses, setStatuses] = useState([]);
  const historyBoxRef = useRef(null);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const fetchData = async () => {
    try {
      const [clientData, historyData, expertsData, statusesData] = await Promise.all([
        apiClient(`/api/clients/${id}`),
        apiClient(`/api/clients/${id}/history`),
        apiClient(`/api/experts`),
        apiClient(`/api/statuses`)
      ]);

      setClient(clientData.client);
      setHistory(historyData);
      setExperts(expertsData);
      setStatuses(statusesData);

      console.log("Полученные данные истории:", historyData);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      const fallbackClient = mockDataContacts.find(client => client.id === parseInt(id));
      setClient(fallbackClient);
      const fallbackHistory = mockDataHistory.find(hist => hist.clientId === parseInt(id));
      setHistory(fallbackHistory ? fallbackHistory.history : []);
      setExperts(mockDataExperts);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchStreamName = async () => {
      if (client?.responsible_id && experts.length > 0) {
        const expert = experts.find(exp => exp.id === client.responsible_id);
        if (expert && expert.stream_id) {
          try {
            const streamData = await apiClient(`/api/potoks/${expert.stream_id}`);
            setStreamName(streamData.stream_name || 'Неизвестный поток');
          } catch (error) {
            console.error("Ошибка при получении потока эксперта:", error);
            setStreamName('Неизвестный поток');
          }
        } else {
          setStreamName('Неизвестный поток');
        }
      }
    };

    fetchStreamName();
  }, [client, experts]);

  useEffect(() => {
    const updateHistory = async () => {
      if (history.length > 0 && statuses.length > 0 && experts.length > 0) {
        const updatedData = await Promise.all(
          history.map(async (entry) => {
            const updatedText = await replaceIdsWithNames(entry.text_to_show, entry.variable, statuses, experts);
            return { ...entry, text_to_show: updatedText };
          })
        );
        console.log('Обновленная история:', updatedData);
        setUpdatedHistory(updatedData);
      }
    };

    updateHistory();
  }, [history, statuses, experts]);

  useEffect(() => {
    if (historyBoxRef.current) {
      historyBoxRef.current.scrollTop = historyBoxRef.current.scrollHeight;
    }
  }, [updatedHistory]);

  const handleMakeExpert = async () => {
    try {
      const response = await apiClient(`/api/clients/${id}/make-expert`, {
        method: "POST",
      });

      if (response && response.ok) {
        alert("Клиент успешно назначен экспертом");
      } else {
        const errorMessage = response?.detail || "Ошибка при назначении клиента экспертом.";
        console.error("Ошибка при назначении клиента экспертом:", errorMessage);
        alert(`Ошибка: ${errorMessage}`);
      }
    } catch (error) {
      console.error("Ошибка при назначении клиента экспертом:", error);
      alert("Произошла ошибка при назначении клиента экспертом. Пожалуйста, попробуйте снова.");
    }
  };

  const handleExpertChange = async (event, newValue) => {
    try {
      const expertId = newValue.id;
      await apiClient(`/api/clients/${id}/change-expert`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ responsible_id: expertId }),
      });
      alert("Ответственный эксперт успешно изменен");
      await sleep(1000);
      await fetchData(); // Перезагружаем данные после изменения
    } catch (error) {
      console.error("Ошибка при изменении ответственного эксперта:", error);
    }
  };

  const handleBackClick = () => {
    const state = location.state || {};
    navigate(-1, { state });
  };

  useEffect(() => {
    if (location.state?.scrollPosition) {
      window.scrollTo(0, location.state.scrollPosition);
    }
  }, [location.state]);

  if (!client) {
    return <Typography>Загрузка...</Typography>;
  }

  const displayValue = (value) => value ? value : 'Не заполнено';
  const getStatusName = (statusId) => {
    const status = statuses.find((s) => s.id === statusId);
    return status ? status.name : 'Неизвестный статус';
  };

  return (
    <Box p="20px">
      <IconButton onClick={handleBackClick} sx={{ mb: "20px" }}>
        <ArrowBackIcon />
      </IconButton>

      <Box display="flex" mt={2}>
        <Box width="50%" pr={2} borderRight="1px solid #ccc">
          <Typography variant="h4" mt={2}>{client.full_name}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Телефон: {displayValue(client.phone_number)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Телеграмм: @{displayValue(client.telegram_account)}</Typography>

          <Box display="flex" alignItems="center" my={2}>
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>Ответственный: </Typography>
            <Autocomplete
              options={experts}
              getOptionLabel={(option) => option.full_name}
              style={{ width: 300, marginLeft: 10 }}
              renderInput={(params) => <TextField {...params} label="Выберите эксперта" variant="outlined" />}
              onChange={handleExpertChange}
              value={experts.find((expert) => expert.id === client.responsible_id) || null}
            />
          </Box>

          <Button variant="contained" color="primary" onClick={handleMakeExpert}>
            Сделать экспертом
          </Button>

          <Typography variant="body1" my={2} sx={{ fontSize: '1rem' }}>Дата обращения: {displayValue(client.request_date)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Статус: {getStatusName(client.status)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>NPS Score: {displayValue(client.nps_score)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Принят психологом: {client.attended_by_psychologist ? "Да" : "Нет"}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Язык обслуживания: {displayValue(client.service_language)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Создано: {displayValue(client.created_at)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Обновлено: {displayValue(client.updated_at)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Количество попыток перераспределения: {displayValue(client.attempt_count)}</Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>Последний назначенный эксперт: {displayValue(client.last_assigned_expert_id)}</Typography>
        </Box>

        <Box width="50%" pl={2}>
          <Typography variant="h5" mb={2} sx={{ fontSize: '1.5rem' }}>История клиента</Typography>
          <Box
            ref={historyBoxRef}
            sx={{
              maxHeight: "75vh",
              overflowY: "auto",
              p: 2,
              border: '1px solid #ccc',
              borderRadius: 1
            }}
          >
            {updatedHistory.length === 0 ? (
              <Typography>История отсутствует или загружается...</Typography>
            ) : (
              updatedHistory.map((entry, index) => (
                <Card key={index} variant="outlined" sx={{ mb: 1, p: 1 }}>  
                  <CardContent sx={{ p: 1 }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Avatar sx={{ bgcolor: "primary.main", mr: 1, width: 30, height: 30 }}>  
                        {getActionIcon(entry.action_type)}
                      </Avatar>
                      <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                        {entry.text_to_show}
                      </Typography> 
                    </Box>
                    <Divider />
                    <Box mt={1}>
                      <Typography variant="body2" color="textSecondary">
                        {new Date(entry.timestamp).toLocaleString()}
                      </Typography>
                      {entry.variable && Object.keys(entry.variable).length > 0 && (
                        <Box mt={1}>
                          {Object.entries(entry.variable).map(([key, value]) => (
                            <Tooltip title={key} key={key}>
                              <Chip
                                label={`${key}: ${value}`}
                                variant="outlined"
                                color="secondary"
                                sx={{ mr: 0.5, mb: 0.5 }} 
                              />
                            </Tooltip>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientCard;
