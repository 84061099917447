import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Task from "./Task";
import apiClient from "../apiClient"; // Импортируем ваш apiClient

const Column = ({ provided, clients, status, experts, handleCardClick }) => {
  const [streams, setStreams] = useState([]);

  useEffect(() => {
    // Функция для получения списка потоков
    const fetchStreams = async () => {
      try {
        const data = await apiClient("/api/potoks");
        setStreams(data);
      } catch (error) {
        console.error("Ошибка при получении списка потоков:", error);
      }
    };

    fetchStreams();
  }, []);

  const getExpertInfo = (responsibleId) => {
    const expert = experts.find((exp) => exp.id === responsibleId);
    return expert
      ? {
          name: expert.full_name,
          stream_name: getStreamName(expert.stream_id),
        }
      : {
          name: "Информация отсутствует",
          stream_name: "Информация отсутствует",
        };
  };

  const getStreamName = (streamId) => {
    const stream = streams.find((str) => str.id === streamId);
    return stream ? stream.stream_name : `Из потока: ${streamId}`;
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <Box
      ref={provided.innerRef}
      {...provided.droppableProps}
      sx={{
        backgroundColor: "#424242", // Темный фон для колонок
        borderRadius: "8px",
        padding: 2,
        minHeight: "200px", // Минимальная высота для колонки
        maxHeight: "75vh", // Ограничиваем высоту колонки
        boxSizing: "border-box",
        overflowY: "auto", // Вертикальный скролл для каждой колонки
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2, color: '#ffffff' }}>
        {status.name} ({clients.length})
      </Typography>
      {clients.map((client, index) => {
        const expertInfo = getExpertInfo(client.responsible_id);
        return (
          <Task
            key={client.id}
            client={client}
            index={index}
            expertInfo={expertInfo}
            formatDateTime={formatDateTime}
            handleCardClick={handleCardClick}
          />
        );
      })}
      {provided.placeholder}
    </Box>
  );
};

export default Column;
