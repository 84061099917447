import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { mockDataExperts } from "../../data/mockData";
import Column from "./Column";
import "./KanbanBoard.css";
import apiClient from "../apiClient";

// Функция для переупорядочивания карточек в рамках одной колонки
const reorderCards = (cards, startIndex, endIndex) => {
  const result = Array.from(cards);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Функция для перемещения карточек между колонками
const moveCard = (sourceCards, destinationCards, sourceIndex, destinationIndex) => {
  const sourceClone = Array.from(sourceCards);
  const destClone = Array.from(destinationCards);
  const [movedCard] = sourceClone.splice(sourceIndex, 1);
  destClone.splice(destinationIndex, 0, movedCard);

  return {
    sourceCards: sourceClone,
    destinationCards: destClone,
  };
};

const KanbanBoard = ({ statuses, clients: initialClients }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [experts, setExperts] = useState([]);
  const [clients, setClients] = useState({});
  const kanbanContainerRef = useRef(null); // Добавляем реф для контейнера Kanban

  useEffect(() => {
    // Фетчинг экспертов
    const fetchExperts = async () => {
      try {
        const data = await apiClient("/api/experts");
        // Проверка на наличие данных
        if (data && Array.isArray(data)) {
          setExperts(data);
        } else {
          throw new Error("Данные экспертов не были получены или пустые");
        }
      } catch (error) {
        console.error(
          "Ошибка при получении данных экспертов, использование локальных данных:",
          error
        );
        setExperts(mockDataExperts);
      }
    };
    
    fetchExperts();    
  }, []);

  useEffect(() => {
    // Группировка клиентов по статусам
    const groupedClients = statuses.reduce((acc, status) => {
      acc[status.id] = initialClients.filter(
        (client) => client.status === status.id
      );
      return acc;
    }, {});
    setClients(groupedClients);
  }, [initialClients, statuses]);

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // Если нет места назначения, ничего не делаем
    if (!destination) return;

    // Если карточка перемещена в то же место
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceStatusId = source.droppableId;
    const destinationStatusId = destination.droppableId;

    // Перемещение внутри одной колонки
    if (sourceStatusId === destinationStatusId) {
      const reorderedCards = reorderCards(
        clients[sourceStatusId],
        source.index,
        destination.index
      );

      setClients((prev) => ({
        ...prev,
        [sourceStatusId]: reorderedCards,
      }));
    } else {
      // Перемещение между разными колонками
      const sourceCards = clients[sourceStatusId];
      const destinationCards = clients[destinationStatusId];

      const { sourceCards: newSourceCards, destinationCards: newDestinationCards } =
        moveCard(sourceCards, destinationCards, source.index, destination.index);

      setClients((prev) => ({
        ...prev,
        [sourceStatusId]: newSourceCards,
        [destinationStatusId]: newDestinationCards,
      }));

      // Обновление статуса клиента на сервере
      const movedClient = newDestinationCards[destination.index];
      try {
        const response = await apiClient(`/api/clients/${movedClient.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: parseInt(destinationStatusId) }),
        });

        if (!response.ok) {
          throw new Error("Ошибка при обновлении статуса клиента");
        }
      } catch (error) {
        console.error(
          "Ошибка при выполнении запроса на обновление статуса:",
          error
        );
      }
    }
  };

  useEffect(() => {
    if (kanbanContainerRef.current) {
      kanbanContainerRef.current.scrollLeft = 0; // Начальная позиция скролла слева
    }
  }, []);

  return (
    <Box
      ref={kanbanContainerRef}
      sx={{
        overflowX: "auto", // Горизонтальная прокрутка
        maxHeight: "75vh",  // Ограничиваем высоту контейнера
        maxWidth: "172vh",
        p: 2,
        border: '1px solid #ccc',
        borderRadius: 1
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            padding: "0px",
            height: "100%",
            minWidth: "100%", // Минимальная ширина для адаптации к количеству колонок
          }}
        >
          {statuses.map((status) => (
            <Droppable key={status.id.toString()} droppableId={status.id.toString()}>
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    display: "inline-block", // Делаем каждый столбец блочным элементом для горизонтальной прокрутки
                    verticalAlign: "top", // Выравнивание столбцов по верхнему краю
                    minWidth: 250, // Устанавливаем фиксированную ширину для столбца
                    maxWidth: 300, // Фиксируем максимальную ширину для столбца
                    marginRight: "10px", // Статическое расстояние между столбцами
                  }}
                >
                  <Column
                    provided={provided}
                    clients={clients[status.id] || []}
                    status={status}
                    experts={experts}
                    handleCardClick={(clientId) =>
                      navigate(`/client/${clientId}`, {
                        state: {
                          searchText: location.state?.searchText || "",
                          sortField: location.state?.sortField || "id",
                          sortOrder: location.state?.sortOrder || "asc",
                          view: location.state?.view || "kanban",
                          selectedColumns: location.state?.selectedColumns || [],
                          scrollPosition: window.scrollY,
                        },
                      })
                    }
                  />
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          ))}
        </Box>
      </DragDropContext>
    </Box>
  );
};

export default KanbanBoard;
