import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { mockDataContacts, mockDataExperts } from "../../data/mockData";
import apiClient from "../apiClient";

const Experts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [experts, setExperts] = useState([]);
  const [streams, setStreams] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("full_name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filteredExperts, setFilteredExperts] = useState([]);

  useEffect(() => {
    const fetchExpertsAndStreams = async () => {
      try {
        const expertsData = await apiClient("/api/experts");
        setExperts(expertsData);

        const streamsData = await apiClient("/api/potoks");
        setStreams(streamsData);
      } catch (error) {
        console.error("Ошибка при получении данных, использование локальных данных:", error);
        setExperts(mockDataExperts);
      }
    };

    fetchExpertsAndStreams();
  }, []);

  useEffect(() => {
    const expertClientCounts = experts.map((expert) => {
      const clientCount = mockDataContacts.filter(client => client.responsible_id === expert.id).length;

      const stream = streams.find((s) => s.id === expert.stream_id);
      const streamName = stream ? stream.stream_name : "Неизвестно";

      return { ...expert, clientCount, stream_name: streamName };
    });

    setFilteredExperts(applyFiltersAndSort(expertClientCounts));
  }, [experts, streams, searchText, sortField, sortOrder]);

  const applyFiltersAndSort = (experts) => {
    return experts
      .filter((expert) =>
        Object.entries(expert).some(([key, value]) =>
          value != null && String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];
        if (aValue == null || bValue == null) return 0;
        if (sortOrder === "asc") {
          return String(aValue).localeCompare(String(bValue));
        } else {
          return String(bValue).localeCompare(String(aValue));
        }
      });
  };

  const handleStatusChange = async (id, currentStatus) => {
    try {
      if (currentStatus === "active") {
        await apiClient(`/api/experts/${id}/deactivate`, {
          method: "POST",
        });
        alert("Эксперт деактивирован");
      } else {
        await apiClient(`/api/experts/${id}/activate`, {
          method: "POST",
        });
        alert("Эксперт активирован");
      }
      setExperts((prev) => 
        prev.map(exp => exp.id === id ? { ...exp, status: currentStatus === "active" ? "inactive" : "active" } : exp)
      );
    } catch (error) {
      console.error(`Ошибка при изменении статуса эксперта:`, error);
      alert(`Не удалось изменить статус эксперта. Проверьте подключение к интернету.`);
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiClient(`/api/experts/${id}`, {
        method: "DELETE",
      });
      alert("Эксперт удален");
      setExperts((prev) => prev.filter(exp => exp.id !== id));
    } catch (error) {
      console.error("Ошибка при удалении эксперта:", error);
      alert("Не удалось удалить эксперта. Проверьте подключение к интернету.");
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { 
      field: "full_name", 
      headerName: "Эксперты", 
      flex: 1, 
      renderCell: (params) => (
        <Button 
          variant="text" 
          sx={{ color: 'inherit' }} 
          onClick={() => navigate(`/experts/${params.row.id}`)}
        >
          {params.value}
        </Button>
      ) 
    },
    { field: "clientCount", headerName: "Количество клиентов", flex: 1 },
    { 
      field: "stream_name", 
      headerName: "Поток", 
      flex: 1 
    },
    { 
      field: "actions", 
      headerName: "Действие", 
      flex: 1, 
      renderCell: (params) => (
        <>
          <Button 
            variant="text" 
            sx={{ color: colors.blueAccent[500] }} 
            onClick={() => handleStatusChange(params.row.id, params.row.status)}
          >
            {params.row.status === "active" ? "Деактивировать" : "Активировать"}
          </Button> / 
          <Button 
            variant="text" 
            sx={{ color: colors.redAccent[500] }} 
            onClick={() => handleDelete(params.row.id)}
          >
            Удалить
          </Button>
        </>
      ) 
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Эксперты"
        subtitle="Управление экспертами"
        documentCount={`Найдено: ${filteredExperts.length}`} // Отображаем количество найденных экспертов
      />

      <Box mb="20px">
        <TextField
          label="Поиск"
          variant="outlined"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          sx={{ marginRight: "20px" }}
        />
        <Select
          value={sortField}
          onChange={(event) => setSortField(event.target.value)}
          sx={{ marginRight: "20px" }}
        >
          {columns.map((column) => (
            <MenuItem key={column.field} value={column.field}>
              {column.headerName}
            </MenuItem>
          ))}
        </Select>
        <Select value={sortOrder} onChange={(event) => setSortOrder(event.target.value)}>
          <MenuItem value="asc">По возрастанию</MenuItem>
          <MenuItem value="desc">По убыванию</MenuItem>
        </Select>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            cursor: "pointer",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredExperts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Experts;
