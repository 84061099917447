const apiClient = (endpoint, options = {}) => {
  const url = `${endpoint}`;

  console.log(`Запрос к API: ${url}`, options);  // Лог запроса

  return fetch(url, options)
    .then(response => {
      console.log(`Ответ от API (${url}):`, response);  // Лог ответа

      if (!response.ok) {
        console.error(`Ошибка сети (${response.status}): ${response.statusText}`);  // Лог ошибки сети
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.json();
    })
    .then(data => {
      console.log(`Полученные данные от API (${url}):`, data);  // Лог данных
      return data;
    })
    .catch(error => {
      console.error(`Ошибка при запросе к API (${url}):`, error);  // Лог ошибки
      throw error;  // Перебрасываем ошибку, чтобы она могла быть обработана выше
    });
};

export default apiClient;
