import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import KanbanBoard from "./KanbanBoard";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { mockDataStatuses } from "../../data/mockData";
import apiClient from "../apiClient";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [statuses, setStatuses] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(
    JSON.parse(localStorage.getItem("selectedColumns")) || [
      "id", "full_name", "phone_number", "telegram_account", "issue",
      "responsible_id", "request_date", "status", "nps_score",
      "attended_by_psychologist", "service_language", "created_at",
      "updated_at", "attempt_count", "previous_experts"
    ]
  );
  const [view, setView] = useState("kanban");
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statuses = await apiClient("/api/statuses");
        setStatuses(statuses.map(({ id, name }) => ({ id, name: name || "Не указан" })));
      } catch {
        setStatuses(mockDataStatuses);
      }
    };

    const fetchClients = async () => {
      try {
        const clients = await apiClient("/api/clients");
        const filteredData = clients.map(client => ({
          id: client.id,
          full_name: client.full_name || "Не указано",
          phone_number: client.phone_number || "Не указан",
          telegram_account: client.telegram_account || "Не указан",
          issue: client.issue || 'Не указана',
          responsible_id: client.responsible_id || 'Не назначен',
          request_date: client.request_date || "Не указана",
          status: client.status || "Не указан",
          nps_score: client.nps_score || 'Не оценен',
          attended_by_psychologist: client.attended_by_psychologist ? 'Да' : 'Нет',
          service_language: client.service_language || "Не указан",
          created_at: formatDateTime(client.created_at),
          updated_at: formatDateTime(client.updated_at),
          attempt_count: client.attempt_count || 0,
          previous_experts: client.previous_experts || 'Отсутствуют',
        }));
        setClients(filteredData);
        setFilteredClients(applyFiltersAndSort(filteredData));
      } catch {
        setClients(mockDataStatuses);
        setFilteredClients(mockDataStatuses);
      }
    };

    fetchStatuses();
    fetchClients();
  }, []);

  const formatDateTime = (dateTime) => {
    if (!dateTime) return "Не указана";
    const date = new Date(dateTime);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "full_name", headerName: "ФИО", flex: 1 },
    { field: "phone_number", headerName: "Номер телефона", flex: 1 },
    { field: "telegram_account", headerName: "Телеграм аккаунт", flex: 1 },
    { field: "issue", headerName: "Проблема", flex: 1 },
    { field: "responsible_id", headerName: "Ответственный эксперт", flex: 1 },
    { field: "request_date", headerName: "Дата обращения", flex: 1 },
    { field: "status", headerName: "Статус", flex: 1 },
    { field: "nps_score", headerName: "NPS Балл", flex: 1 },
    { field: "attended_by_psychologist", headerName: "Принят психологом", flex: 1 },
    { field: "service_language", headerName: "Язык обслуживания", flex: 1 },
    { field: "created_at", headerName: "Дата создания", flex: 1 },
    { field: "updated_at", headerName: "Дата обновления", flex: 1 },
    { field: "attempt_count", headerName: "Количество попыток", flex: 1 },
    { field: "previous_experts", headerName: "Предыдущие эксперты", flex: 1 },
  ].filter((column) => selectedColumns.includes(column.field));

  const handleRowClick = (params) => {
    navigate(`/client/${params.id}`, {
      state: { searchText, sortField, sortOrder, view, selectedColumns, scrollPosition: window.scrollY },
    });
  };

  const applyFiltersAndSort = (clients) => {
    return clients
      .filter((client) =>
        Object.values(client).some((value) =>
          value && value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => sortOrder === "asc" ? a[sortField] > b[sortField] ? 1 : -1 : a[sortField] < b[sortField] ? 1 : -1);
  };

  useEffect(() => {
    setFilteredClients(applyFiltersAndSort(clients));
  }, [searchText, sortField, sortOrder, clients]);

  useEffect(() => {
    localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  const toggleView = () => setView(view === "table" ? "kanban" : "table");

  return (
    <Box m="20px">
      <Header
        title="ВОРОНКА"
        subtitle={`Состояние запросов`}
        documentCount={`Найдено: ${filteredClients.length}`}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={toggleView}
        sx={{ marginBottom: "20px" }}
      >
        {view === "table" ? "Вид канбаном" : "Вид таблицы"}
      </Button>

      <Box mb="20px">
        <TextField
          label="Поиск"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ marginRight: "20px" }}
        />
        <Select
          value={sortField}
          onChange={(e) => setSortField(e.target.value)}
          sx={{ marginRight: "20px" }}
        >
          {columns.map((column) => (
            <MenuItem key={column.field} value={column.field}>
              {column.headerName}
            </MenuItem>
          ))}
        </Select>
        <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
          <MenuItem value="asc">По возрастанию</MenuItem>
          <MenuItem value="desc">По убыванию</MenuItem>
        </Select>
      </Box>

      {view === "table" ? (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              cursor: "pointer",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={filteredClients}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            onRowClick={handleRowClick}
          />
        </Box>
      ) : (
        <Box m="40px 0 0 0" height="75vh">
          <KanbanBoard statuses={statuses} clients={filteredClients} />
        </Box>
      )}
    </Box>
  );
};

export default Contacts;
