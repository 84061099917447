import React from "react";
import { Paper, Typography } from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";

const Task = ({ client, index, expertInfo, formatDateTime, handleCardClick }) => {
return (
    <Draggable
        key={client.id.toString()}
        draggableId={client.id.toString()}
        index={index}
    >

      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            padding: 2,
            marginBottom: 1,
            backgroundColor: snapshot.isDragging ? "#616161" : "#757575", // Цвет карточки в нормальном состоянии
            cursor: "pointer",
            userSelect: "none",
            color: '#ffffff', // Цвет текста на карточках
            minWidth: 240, // Ширина таска фиксирована как и ширина столбца
            boxSizing: "border-box",
            overflowWrap: "break-word", // Перенос текста для длинных слов
            wordWrap: "break-word", // Перенос длинных слов
          }}
          onClick={() => handleCardClick(client.id)}
        >
          <Typography variant="subtitle2">
            {client.full_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ color: '#e0e0e0' }}>
            Дата и время: {formatDateTime(client.created_at)}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ color: '#e0e0e0' }}>
            Из потока: {expertInfo.stream_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ color: '#e0e0e0' }}>
            Ответственный: {expertInfo.name}
          </Typography>
        </Paper>
      )}
    </Draggable>
  );
};


export default Task;
