import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from "../apiClient"; // Assuming you have an API client

const ClientsAnalytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [purchaseData, setPurchaseData] = useState({
    total_clients: 0,
    sold_clients: 0,
    not_sold_clients: 0,
    in_process_clients: 0,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchPurchaseData = async () => {
    try {
      const response = await apiClient("/api/reports/client-purchase", {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      setPurchaseData(response);
    } catch (error) {
      console.error("Ошибка при получении данных о покупках клиентов:", error);
    }
  };

  useEffect(() => {
    fetchPurchaseData();
  }, []);

  return (
    <Box m="20px">
      <Header title="Отчет по клиентам" subtitle="Отчет о покупках клиентов за выбранный период" />

      <Box mt="20px" display="flex" justifyContent="space-between" mb="20px">
        <TextField
          label="Дата начала"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="Дата окончания"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button variant="contained" color="info" onClick={fetchPurchaseData}>
          Обновить отчет
        </Button>
      </Box>

      <Box mt="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bgcolor={colors.primary[400]}
          mb="10px"
        >
          <Typography>Общее количество клиентов</Typography>
          <Typography>{purchaseData.total_clients}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bgcolor={colors.primary[400]}
          mb="10px"
        >
          <Typography>Количество клиентов, в работе</Typography>
          <Typography>{purchaseData.in_process_clients}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bgcolor={colors.primary[400]}
          mb="10px"
        >
          <Typography>Количество клиентов, которые купили</Typography>
          <Typography>{purchaseData.sold_clients}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          bgcolor={colors.primary[400]}
          mb="10px"
        >
          <Typography>Количество клиентов, которые не купили</Typography>
          <Typography>{purchaseData.not_sold_clients}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientsAnalytics;
