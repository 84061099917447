import React, { useState, useEffect } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import apiClient from '../apiClient';

const PotokDetails = () => {
  const { id } = useParams(); // Получаем potok_id из URL
  const navigate = useNavigate();
  const theme = useTheme(); // Получаем текущую тему
  const colors = tokens(theme.palette.mode);
  const [experts, setExperts] = useState([]);
  const [potok, setPotok] = useState(null);

  useEffect(() => {
    const fetchPotokDetails = async () => {
      try {
        const potokResponse = await apiClient(`/api/potoks/${id}`);
        if (!potokResponse.ok) throw new Error("Ошибка сети при получении данных потока");
        const potokData = await potokResponse.json();
        setPotok(potokData);

        const expertsResponse = await apiClient(`/api/experts?potok_id=${id}`);
        if (!expertsResponse.ok) throw new Error("Ошибка сети при получении данных экспертов");
        const expertsData = await expertsResponse.json();
        setExperts(expertsData);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
        alert("Не удалось загрузить данные. Проверьте подключение к интернету.");
      }
    };

    fetchPotokDetails();
  }, [id]);

  const handleDeactivateExpert = async (expertId) => {
    try {
      const response = await apiClient(`/api/experts/${expertId}/deactivate`, {
        method: "POST",
      });
      if (!response.ok) throw new Error("Ошибка деактивации эксперта");
      alert("Эксперт деактивирован");
      setExperts((prev) => prev.map(expert => expert.id === expertId ? { ...expert, active: false } : expert));
    } catch (error) {
      console.error("Ошибка при деактивации эксперта:", error);
      alert("Не удалось деактивировать эксперта. Проверьте подключение к интернету.");
    }
  };

  const handleDeleteExpert = async (expertId) => {
    try {
      const response = await apiClient(`/api/experts/${expertId}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Ошибка удаления эксперта");
      alert("Эксперт удален");
      setExperts((prev) => prev.filter(expert => expert.id !== expertId));
    } catch (error) {
      console.error("Ошибка при удалении эксперта:", error);
      alert("Не удалось удалить эксперта. Проверьте подключение к интернету.");
    }
  };

  if (!potok) {
    return <Typography>Загрузка...</Typography>;
  }

  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" mb="20px">
        <IconButton onClick={() => navigate('/potok')}>
          <ArrowBackIcon />
        </IconButton>
        <Header title={`Поток: ${potok.stream_name}`} subtitle="Детали потока и эксперты" />
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>Эксперты в потоке</Typography>
        <Box>
          {experts.length === 0 ? (
            <Typography>Эксперты отсутствуют.</Typography>
          ) : (
            experts.map((expert) => (
              <Box key={expert.id} display="flex" alignItems="center" justifyContent="space-between" mb="10px" p="10px" bgcolor={colors.primary[400]}>
                <Typography flex={1}>{expert.full_name}</Typography>
                <Typography flex={1}>{expert.clientCount} клиентов</Typography>
                <Typography flex={1}>{potok.stream_name}</Typography>
                <Box>
                  <IconButton onClick={() => handleDeactivateExpert(expert.id)} sx={{ color: colors.blueAccent[400] }}>
                    <ToggleOffIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteExpert(expert.id)} sx={{ color: colors.redAccent[400] }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PotokDetails;
